<template>
	<b-container fluid class="px-0">
		<h3 style="margin: 0">{{ this.FormMSG(10, 'Budget tracking') }}</h3>
		<budget-dashboard-department-selector :departments="departments" @changeSelectedDepartment="setSelectedDepartmentValue" />
		<budget-dashboard-chart :graph-data="budgetDataPerDimension" />
	</b-container>
</template>

<script>
import gql from 'graphql-tag';
import BudgetDashboardDepartmentSelector from './BudgetDashboardDepartmentSelector';
import BudgetDashboardChart from './BudgetDashboardChart';
import languageMessages from '@/mixins/languageMessages';

function reduceBudgetDataByKey(data, key, dimensions = []) {
	const reduced = data.reduce((agg, elem) => {
		const dimension = dimensions.find((d) => d.value === elem[key]);
		if (!agg[elem[key]]) {
			agg[elem[key]] = {
				amountLeft: elem.amountLeft,
				amountTotal: elem.amountTotal + elem.purchaseOrdersTotal,
				dimension
			};
		} else {
			agg[elem[key]].amountLeft += elem.amountLeft;
			agg[elem[key]].amountTotal += elem.amountTotal + elem.purchaseOrdersTotal;
		}
		return agg;
	}, {});
	const ret = Object.keys(reduced).map((key) => {
		return {
			key,
			amountLeft: reduced[key].amountLeft,
			amountTotal: reduced[key].amountTotal,
			dimension: reduced[key].dimension
		};
	});
	return ret;
}

function reduceBudgetDataPerCategory(data) {
	const reduced = data.reduce((agg, elem) => {
		if (!agg[elem.category]) {
			agg[elem.category] = {
				amountLeft: elem.amountLeft,
				amountTotal: elem.amountTotal + elem.purchaseOrdersTotal,
				dimension: elem.categoryName
			};
		} else {
			agg[elem.category].amountLeft += elem.amountLeft;
			agg[elem.category].amountTotal += elem.amountTotal + elem.purchaseOrdersTotal;
		}
		return agg;
	}, {});
	const ret = Object.keys(reduced).map((category) => {
		return {
			key: category,
			amountLeft: reduced[category].amountLeft,
			amountTotal: reduced[category].amountTotal,
			dimension: {
				message: reduced[category].dimension
			}
		};
	});
	return ret;
}
export default {
	name: 'BudgetDashboard',
	mixins: [languageMessages],
	components: {
		BudgetDashboardDepartmentSelector,
		BudgetDashboardChart
	},
	apollo: {
		budgetData: {
			query: gql`
				query {
					GetBudgetDataDetailList {
						department
						category
						amountTotal
						amountLeft
						purchaseOrdersTotal
					}
					GetDepartments {
						id
						value
						message
					}
					GetBudgetCategoryList(DepartmentNumber: -1) {
						category
						description
						department
					}
				}
			`,
			update: (data) => {
				const { GetBudgetDataDetailList, GetBudgetCategoryList } = data;
				const budgetDataDetailWithCategories = GetBudgetDataDetailList.map((budgetDetail) => {
					const { category, department } = budgetDetail;
					const categoryDetail = GetBudgetCategoryList.find((c) => c.department === department && category === c.category);
					if (categoryDetail) {
						budgetDetail.categoryName = categoryDetail.description;
					} else {
						budgetDetail.categoryName = 'Unkown';
					}
					return budgetDetail;
				});
				return budgetDataDetailWithCategories;
			},
			result({ data }) {
				const allDepsIds = Array.from(new Set(data.GetBudgetDataDetailList.map((d) => d.department)));
				const allDeps = allDepsIds.map((depValue) => {
					const dep = data.GetDepartments.find((d) => d.value === depValue);
					if (!dep) {
						return {
							value: depValue,
							message: 'UNKNOWN',
							id: '__NA__'
						};
					}
					return {
						value: depValue,
						message: dep.message,
						id: dep.id
					};
				});
				this.setAvailableDepartments(allDeps);
			},
			fetchPolicy: 'no-cache'
		}
	},
	data: function () {
		return {
			budgetData: [],
			departments: [],
			departmentData: [],
			selectedDepartmentValue: '__ALL__'
		};
	},
	computed: {
		budgetPerDepartment: function () {
			return reduceBudgetDataByKey(this.budgetData, 'department', this.departments);
		},
		budgetPerCategory: function () {
			if (this.selectedDepartmentValue !== '__ALL__') {
				const selectedDepartmentValue = this.selectedDepartmentValue;
				const dataForSelectedDepartement = this.budgetData.filter((d) => d.department === selectedDepartmentValue);
				const dataPerCategory = reduceBudgetDataPerCategory(dataForSelectedDepartement);
				return dataPerCategory;
			} else {
				return { message: 'no data' };
			}
		},
		budgetDataPerDimension: function () {
			if (this.selectedDepartmentValue !== '__ALL__') {
				return this.budgetPerCategory;
			}
			//console.log( "this.budgetPerDepartment:", this.budgetPerDepartment);
			return this.budgetPerDepartment;
		}
	},
	methods: {
		setAvailableDepartments(departments) {
			this.departments = departments;
		},
		setSelectedDepartmentValue(depValue) {
			this.selectedDepartmentValue = depValue;
		}
	}
};
</script>
